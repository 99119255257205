/* eslint-disable @next/next/no-img-element */

import { ImgHTMLAttributes, DetailedHTMLProps } from "react";

export type S3ImageProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

export const S3Image = ({
  src,
  alt,
  className = "",
  width = 50,
  height = 50,
  ...props
}: S3ImageProps) => {
  return (
    <img
      src={src}
      alt={alt}
      className={className}
      width={width}
      height={height}
      {...props}
    />
  );
};
