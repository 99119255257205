import type { AgreementTextPart } from "~/api/interfaces";
import { S3Image } from "@kenai/core";
import { Box, Typography } from "@mui/material";

type TextPart = AgreementTextPart;

interface Replacement {
  regex: RegExp;
  value: any;
}

interface Props {
  textParts?: TextPart[];
  replacements?: Replacement[];
}

export default function MapTextPartsToComponent({
  textParts,
  replacements = [],
}: Props) {
  if (!textParts) return null;

  const replaceText = (text: string) => {
    for (const replacement of replacements) {
      text = text.replace(replacement.regex, replacement.value);
    }
    return text;
  };

  const MappedComponents = (
    <>
      {textParts.map((item, i) => {
        const key = `${item.type}-item-${i}`;
        switch (item.type) {
          case "heading":
            return (
              <Typography
                key={key}
                variant="h5"
                marginBottom={2}
                marginTop={i === 0 ? 0 : 4}
              >
                {replaceText(item.text)}
              </Typography>
            );
          case "paragraph":
            return (
              <Typography key={key} variant="body1" marginBottom={1}>
                {replaceText(item.text)}
              </Typography>
            );
          case "list":
            const listArray = item.text.split("||||");
            return (
              <Box
                component="ul"
                key={key}
                className="list-disc list-inside sm:ml-6 ml-4"
                marginBottom={2}
              >
                {listArray.map((listItem, listItemIndex) => (
                  <Box
                    component="li"
                    key={`${listItemIndex}-item`}
                    marginBottom={0.5}
                  >
                    {replaceText(listItem)}
                  </Box>
                ))}
              </Box>
            );
          case "image":
            return (
              <div key={key}>
                <p> {replaceText(item.text)}</p>
                {item.imagePresignedLink && (
                  <S3Image
                    src={item.imagePresignedLink}
                    alt="presigned graphic"
                    width={400}
                    height={400}
                    className="object-contain"
                  />
                )}
              </div>
            );
          default:
            return null;
        }
      })}
    </>
  );

  return MappedComponents;
}
