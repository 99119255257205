import { InviteSteps } from "~/slices/flow-control";
import { useRouter } from "next/router";

const debugAllowed =
  process.env.DEBUG === "kenai:*" || process.env.NODE_ENV === "development";

interface DEBUG {
  DEBUG_STEP?: InviteSteps;
  DEBUG_AUTH?: "phone" | "otp";
  DEBUG_PHONE_NUMBER?: string;
}

export function useDebug(): DEBUG {
  const query = useRouter().query as any;

  if (!debugAllowed) {
    return {};
  }

  const DEBUG_STEP = query.debugStep;
  const DEBUG_AUTH = query.debugAuth;
  const DEBUG_PHONE_NUMBER = query.phoneNumber;

  return { DEBUG_STEP, DEBUG_AUTH, DEBUG_PHONE_NUMBER };
}
