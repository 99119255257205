export const createTime = (label: string) => {
  let startTime;
  return {
    start: () => {
      startTime = performance.now();
    },
    end: (additionalLabel = "") => {
      const endTime = performance.now();
      let message = `${label}: ${endTime - startTime}ms`;

      if (additionalLabel) {
        message += ` (${additionalLabel})`;
      }
      console.log(message);
    },
  };
};
