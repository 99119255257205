import {
  CircularProgress,
  CircularProgressProps,
  Step,
  StepLabel,
  StepLabelProps,
  Stepper as MuiStepper,
  StepProps,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import classNames from "classnames";
import { useMemo } from "react";

type Label =
  | string
  | {
      title: string;
      subtitle: string;
    };

export interface ToolbarStepperProps<k extends string = string> {
  steps: k[];
  skippedSteps?: k[];
  disabledSteps?: k[];

  activeStep: number;
  labelMapper?: Record<k, Label>;
  stepProps?: StepProps | ((label: string) => StepProps);
  stepLabelProps?: StepLabelProps | ((label: string) => StepLabelProps);
  onBack?: (prevActiveStep: number) => void;
  onNext?: (prevActiveStep: number) => void;
  onChangeStep: (index: number, step: k) => void;
}

class Helper {
  static getFunctionalProp(
    label: string,
    prop:
      | ToolbarStepperProps["stepProps"]
      | ToolbarStepperProps["stepLabelProps"]
  ) {
    if (typeof prop === "function") {
      return prop(label);
    }
    return prop;
  }
  static getLabelFromLabelMapper(
    label: string,
    labelMapper?: ToolbarStepperProps["labelMapper"]
  ) {
    return labelMapper?.[label] ?? label;
  }
  static getNextIndex<K extends string = string>(
    activeStep: number,
    steps: K[],
    disabledSteps: K[] = []
  ): number {
    const isLastStep = activeStep === steps.length - 1;
    const nextStepIndex = isLastStep ? 0 : activeStep + 1;
    const stepName = steps[nextStepIndex];
    const isDisabled = disabledSteps.includes(stepName);

    if (isDisabled) {
      return activeStep;
    }

    return nextStepIndex;
  }
  static getStepProgress(activeStep: number, stepsTotal: number) {
    return Math.min(100, Math.round((activeStep / stepsTotal) * 100)) + 25;
  }
}

function MobileStepper(
  props: { progressProps?: CircularProgressProps } & Pick<
    ToolbarStepperProps,
    "steps" | "activeStep" | "labelMapper" | "onChangeStep" | "disabledSteps"
  >
) {
  const stepsTotal = props.steps.length;
  const stepProgress = useMemo(
    () => Helper.getStepProgress(props.activeStep, stepsTotal),
    [props.activeStep, stepsTotal]
  );

  const labels = useMemo(() => {
    return {
      activeStepLabel: Helper.getLabelFromLabelMapper(
        props.steps[props.activeStep],
        props.labelMapper
      ),
      nextStepLabel: Helper.getLabelFromLabelMapper(
        props.steps[props.activeStep + 1],
        props.labelMapper
      ),
      counter: `${props.activeStep + 1} of ${stepsTotal}`,
    };
  }, [props.activeStep, props.labelMapper, props.steps, stepsTotal]);

  const disabled =
    props.disabledSteps?.includes(props.steps[props.activeStep + 1]) ?? false;

  return (
    <div className={classNames("flex space-x-4 -mr-2", "justify-end")}>
      <div
        className="mobile-stepper-labels flex flex-col justify-center items-end align-middle"
        onClick={() => {
          if ("onChangeStep" in props && !disabled) {
            const nextStepIndex = Helper.getNextIndex(
              props.activeStep,
              props.steps,
              props.disabledSteps
            );
            props.onChangeStep(nextStepIndex, props.steps[nextStepIndex]);
          }
        }}
      >
        <Typography variant="h5" color="text.primary">
          {labels.activeStepLabel}
        </Typography>
        {labels.nextStepLabel && (
          <Typography variant="caption" color="text.secondary">
            Next: {labels.nextStepLabel}
          </Typography>
        )}
      </div>
      <div className="relative inline-flex">
        <CircularProgress
          variant="determinate"
          thickness={3}
          size={60}
          value={stepProgress}
          {...props.progressProps}
        />
        <div className="flex items-center justify-center top-0 right-0 bottom-0 left-0 absolute">
          <Typography variant="body2" component="span" color="primary">
            {labels.counter}
          </Typography>
        </div>
      </div>
    </div>
  );
}

const DesktopStepper = (props: ToolbarStepperProps) => {
  return (
    <MuiStepper activeStep={props.activeStep}>
      {props.steps.map((label, stepIndex) => {
        const disabled = props.disabledSteps?.includes(label) ?? false;
        return (
          <Step
            key={label}
            {...Helper.getFunctionalProp(label, props.stepProps)}
            disabled={disabled}
            onClick={() => {
              if ("onChangeStep" in props && !disabled) {
                props.onChangeStep(stepIndex, props.steps[stepIndex]);
              }
            }}
            completed={props.skippedSteps?.includes(label)}
            className={classNames(!disabled && "cursor-pointer")}
          >
            <StepLabel>
              {Helper.getLabelFromLabelMapper(label, props.labelMapper)}
            </StepLabel>
          </Step>
        );
      })}
    </MuiStepper>
  );
};

export const ToolbarStepper = <k extends string = string>(
  props: ToolbarStepperProps<k>
) => {
  const mobileDevice = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );

  if (mobileDevice) {
    return (
      <MobileStepper
        steps={props.steps}
        activeStep={props.activeStep}
        labelMapper={props.labelMapper}
        onChangeStep={props.onChangeStep as any} // TODO: see why this causes typing error (expected false positive)
        disabledSteps={props.disabledSteps}
      />
    );
  }

  return (
    <DesktopStepper
      steps={props.steps}
      activeStep={props.activeStep}
      labelMapper={props.labelMapper}
      onChangeStep={props.onChangeStep as any} // TODO: see why this causes typing error (expected false positive)
      disabledSteps={props.disabledSteps}
    />
  );
};
