import classNames from "classnames";
import { FC } from "react";

const CenterPage: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <div
      className={classNames(
        className,
        "h-full min-h-screen grid text-left py-10 md:py-20  overflow-y-scroll place-content-center"
      )}
    >
      {children}
    </div>
  );
};

export default CenterPage;
