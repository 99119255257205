import type { VISITOR_AGREEMENT } from "~/api/interfaces";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { createContext } from "react";

export interface InvitesConfigContextValue {
  inviteToken: string;
  entityAgreement: VISITOR_AGREEMENT;
}

interface InvitesProviderProps extends InvitesConfigContextValue {
  children?: ReactNode;
}

export const InvitesConfigContext = createContext<InvitesConfigContextValue>(
  null as any
);

export const InvitesConfigProvider: FC<InvitesProviderProps> = (props) => {
  const { children } = props;

  return (
    <InvitesConfigContext.Provider
      value={{
        inviteToken: props.inviteToken,
        entityAgreement: props.entityAgreement,
      }}
    >
      {children}
    </InvitesConfigContext.Provider>
  );
};

InvitesConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
  inviteToken: PropTypes.string.isRequired,
  entityAgreement: PropTypes.any.isRequired,
};

export const InvitesConfigConsumer = InvitesConfigContext.Consumer;
