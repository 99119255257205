import { Status } from "@kenai/core";
import { Typography } from "@mui/material";
import React from "react";

interface TokenErrorProps {
  headerText?: string;
  bodyText?: string;
  message?: string;
}

export default function TokenError(props: TokenErrorProps) {
  const { headerText, bodyText } = props;
  const message = bodyText || props.message;

  if (bodyText && props.message) {
    throw new Error("message and bodyText are mutually exclusive");
  }

  return (
    <Status variant="exclamation" title={headerText}>
      {message && (
        <Typography variant="body1" className="pt-6">
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </Typography>
      )}
    </Status>
  );
}
