import { Box, Typography } from "@mui/material";
import dynamic from "next/dynamic";
import { FC, ReactNode } from "react";

const variant = {
  exclamation: dynamic(() => import("../../icons/status-exclamation")),
  processed: dynamic(() => import("../../icons/status-processed")),
  fish: dynamic(() => import("../../icons/status-fish")),
} as const;

const sizeMap = {
  small: 100,
  medium: 200,
  large: 400,
} as const;

type StatusProps = {
  variant?: keyof typeof variant;
  icon?: ReactNode;
  title?: string;
  message?: string;
  size?: keyof typeof sizeMap;
};

export const Status: FC<StatusProps> = (props) => {
  const Icon = props.variant ? variant[props.variant] : undefined;
  const CustomIcon = props.icon || null;
  const size = sizeMap[props.size || "medium"];
  return (
    <Box
      display="grid"
      sx={{
        placeItems: "center",
        placeContent: "center",
        textAlign: "center",
        height: "100%",
      }}
    >
      <Box sx={{ w: size, h: size, mb: 6 }}>
        {CustomIcon}
        {Icon && <Icon color="primary" sx={{ width: size, height: size }} />}
      </Box>

      {props.title && <Typography variant="h4">{props.title}</Typography>}
      {props.message && (
        <Typography variant="body1" color="textSecondary">
          {props.message}
        </Typography>
      )}
      {props.children}
    </Box>
  );
};
