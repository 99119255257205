import { generatePalette, S3Image, ToolbarStepper } from "@kenai/core";
import { clientLogger, createDebugger } from "@kenai/utils";
import * as Sentry from "@sentry/nextjs";
import classNames from "classnames";
import { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import dynamic from "next/dynamic";
import Head from "next/head";
// We dont lazy load Agreement because it is the first page to show.
import { useRouter } from "next/router";
import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import API, { enums } from "~/api";
import createApi from "~/api/createApi";
import { TokenError } from "~/api/errors/TokenError";
import Agreement from "~/components/agreement";
import { CenterPage } from "~/components/Layout";
import TokenErrorStatus from "~/components/TokenError";
import { InvitesConfigProvider } from "~/contexts/invites-config-context";
import { useDebug } from "~/hooks/use-debug";
import { useEntityConfig } from "~/hooks/use-entity-config";
import { useInvitesConfig } from "~/hooks/use-invites-config";
import {
  API_ENDPOINT_COOKIE,
  INVITES_TOKEN_COOKIE,
  LOGGING_COOKIE,
} from "~/lib/constants";
import { getIntl } from "~/lib/intl";
import { setEntityConfig } from "~/slices/entity-config";
import { nextStep, setActiveStep, useActiveSteps } from "~/slices/flow-control";
import { useDispatch, useSelector } from "~/store";
import loadIntlMessages from "~/utils/load-intl-messages";
import { logger } from "~/utils/logger";
import { createTime } from "~/utils/time";
const Authentication = dynamic(() => import("~/components/authentication"));
const CheckIn = dynamic(() => import("~/components/check-in"));
const Feedback = dynamic(() => import("~/components/feedback"));
const Induction = dynamic(() => import("~/components/induction"));
const Parking = dynamic(() => import("~/components/parking"));
const Profile = dynamic(() => import("~/components/profile"));
const Summary = dynamic(() => import("~/components/summary"));
const ErrorPage = dynamic(() => import("~/components/ErrorPage"));

const debug = createDebugger("invites");

type PageProps = InferGetServerSidePropsType<typeof getServerSideProps>;

const scrollToTop = () => {
  if (typeof window !== "undefined") {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};

const useUpdateHierarchyConfig = (
  endpoint: PageProps["endpoint"],
  inviteToken: PageProps["inviteToken"]
) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    if (!hydrated) {
      const api = new API(endpoint, router.locale);
      api.getEntityConfig(inviteToken, 2).then(({ configData }) => {
        setHydrated(true);
        dispatch(setEntityConfig(configData));
      });
    }
  }, [endpoint, router.locale, inviteToken, dispatch, hydrated]);

  return {
    hydrated,
  };
};

export default function VisitorInvite(props: PageProps) {
  const steps = useActiveSteps();
  const dispatch = useDispatch();
  const { entityAgreement } = useInvitesConfig();
  const entityConfig = useEntityConfig();
  const { DEBUG_STEP } = useDebug();
  const { hydrated } = useUpdateHierarchyConfig(
    props.endpoint,
    props.inviteToken
  );

  const currentStep = DEBUG_STEP || steps.currentStep;

  useEffect(() => scrollToTop(), [currentStep]);

  const time = createTime(`VIEW:${currentStep}`);
  time.start();
  if (currentStep === "agreement") {
    time.end();
    return (
      <Agreement
        agreementDetails={entityAgreement}
        entityConfig={entityConfig}
        mandatoryAgreementRead
        onAgreementContinue={() => dispatch(nextStep())}
        hideCompanyLogoOnLanding={false}
        headingText={""}
        subHeadingText={""}
        agreementLinkText={""}
        isLoading={!hydrated}
      />
    );
  }

  if (currentStep === "authentication") {
    time.end();
    return <Authentication />;
  }

  if (currentStep === "profile") {
    time.end();
    return <Profile />;
  }

  if (currentStep === "parking") {
    time.end();
    return <Parking />;
  }

  if (currentStep === "checkin") {
    time.end();
    return <CheckIn />;
  }

  if (currentStep === "induction") {
    time.end();
    return <Induction />;
  }

  if (currentStep === "summary") {
    time.end();
    return <Summary />;
  }

  throw new Error(`The step is not defined`);
}

const StateTest = () => {
  const [state, setState] = useState(new Date().toTimeString());

  if (process.env.NODE_ENV !== "development") {
    return null;
  }

  return (
    <input
      className="absolute top-0 left-0 z-50 opacity-50 text-xs"
      value={`rendered: ${state}`}
      onChange={(v) => {
        setState(v.target.value.replace("rendered: ", ""));
      }}
    />
  );
};

const Layout: FC = ({ children }) => {
  const entity = useSelector((state) => ({
    logo: state.entityConfig.logo,
    companyName: state.entityConfig.companyName,
  }));
  const { availableStepsList, currentStep, currentStepIndex } =
    useActiveSteps();
  const tokenError = useSelector((state) => state.flowControl.tokenError);
  const visibleSteps = useSelector((state) =>
    availableStepsList.filter((step) => {
      if (!state.authentication?.authenticated) {
        return ["authentication", "agreement"].includes(step);
      }
      return true;
    })
  );
  const profileSubmitted = useSelector(
    (state) => state.flowControl.profileSubmission.submissionResult
  );

  const dispatch = useDispatch();
  const handleStepChange = useCallback(
    (stepIndex: number) => {
      const step = visibleSteps[stepIndex];

      clientLogger.log(step, visibleSteps, stepIndex);

      dispatch(setActiveStep(step));
    },
    [dispatch, visibleSteps]
  );

  if (tokenError) {
    return (
      <CenterPage>
        <div className="container max-w-lg">
          <TokenErrorStatus {...tokenError} />
        </div>
      </CenterPage>
    );
  }

  if (profileSubmitted) {
    return <Feedback />;
  }

  const showLogo = entity.logo;
  const showHeader = currentStep !== "agreement";

  return (
    <div>
      <Head>
        <title>{entity.companyName} - Invite</title>
      </Head>
      <StateTest />
      {showHeader && (
        <header className="flex items-center py-2 border-b shadow fixed bg-slate-50 w-full h-[77px]">
          {showLogo && (
            <div className="px-4 inline-block">
              <S3Image
                src={entity.logo}
                width={100}
                height={80}
                alt={entity.companyName}
                className="object-contain"
              />
            </div>
          )}
          <div className="flex-grow container">
            <ToolbarStepper<(typeof visibleSteps)[number]>
              steps={visibleSteps}
              labelMapper={{
                agreement: "Agreement",
                authentication: "Authentication",
                profile: "Profile",
                induction: "Induction",
                parking: "Parking",
                checkin: "Check In",
                summary: "Summary",
              }}
              activeStep={currentStepIndex}
              onChangeStep={handleStepChange}
            />
          </div>
        </header>
      )}

      <main
        className={classNames(
          "overflow-y-auto bg-white",
          showHeader ? "mt-[77px] h-[calc(100vh-77px)]" : "h-screen"
        )}
      >
        {children}
      </main>
    </div>
  );
};

VisitorInvite.getLayout = (
  page: ReactElement<PageProps>,
  props = page.props
) => {
  if ("tokenError" in props) {
    return (
      <CenterPage>
        <div className="container max-w-lg">
          <TokenErrorStatus {...props.tokenError} />
        </div>
      </CenterPage>
    );
  }

  if ("errorCode" in props) {
    return (
      <ErrorPage
        statusCode={props.errorCode}
        message={
          "errorMessage" in props ? props.errorMessage : "An error occurred"
        }
      />
    );
  }

  const parseJSON = (value: any) => {
    try {
      return JSON.parse(value);
    } catch (error) {
      console.error(error);
      return value;
    }
  };

  return (
    <InvitesConfigProvider
      inviteToken={props.inviteToken.value}
      entityAgreement={parseJSON(props.entityAgreement)}
    >
      <Layout>{page}</Layout>
    </InvitesConfigProvider>
  );
};

const time = createTime("getServerSideProps");
export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  time.start();
  const intl = getIntl(context.locale);
  const cookies = context.req.cookies;
  const logging = cookies?.[LOGGING_COOKIE] === "yes";

  if (logging) {
    debug.enable();
  } else {
    debug.disable();
  }

  try {
    const query = context.query as Record<string, string>;
    const api = await createApi(context.req, context.res, context.locale);
    const inviteToken = await api.getInviteToken(query);
    context.res.setHeader("set-cookie", [
      `${INVITES_TOKEN_COOKIE}=${inviteToken.value}`,
      `${API_ENDPOINT_COOKIE}=${api.endpoint}`,
    ]);

    const entityConfigTime = createTime("getEntityConfig");
    entityConfigTime.start();
    const partialEntityConfig = await api
      .getEntityConfig(inviteToken, 1)
      .then((res) => {
        entityConfigTime.end();
        return res;
      });
    if (
      partialEntityConfig.key !== enums.API_RESPONSE_KEYS.OPERATION_PROCESSED
    ) {
      debug("entityConfig", partialEntityConfig);
      entityConfigTime.end("Issue resolving entity config");
      throw {
        code: 500,
        message: intl.formatMessage({
          defaultMessage: "Issue resolving entity config",
          id: "9zuPFS",
        }),
        entityConfig: partialEntityConfig,
      };
    }

    const entityAgreementTime = createTime("getEntityAgreement");
    entityAgreementTime.start();
    const entityAgreement = await api
      .retrieveEntityAgreement(inviteToken.value)
      .then((res) => {
        entityAgreementTime.end();
        return res;
      });
    if (entityAgreement.key !== enums.API_RESPONSE_KEYS.OPERATION_PROCESSED) {
      debug("entityAgreement", entityAgreement);
      entityAgreementTime.end("Issue resolving agreement");
      throw {
        code: 500,
        message: intl.formatMessage({
          defaultMessage: "Issue resolving agreement",
          id: "doeBYz",
        }),
        entityConfig: partialEntityConfig,
        entityAgreement,
      };
    }

    const primaryColor = partialEntityConfig.configData?.branding?.primary;
    const primaryPalette = generatePalette(primaryColor || "#4CB75B");

    time.end();
    return {
      props: {
        entityConfig: JSON.stringify(partialEntityConfig.configData), // we stringify this to make it smaller (reduces from ~300kb to ~100kb)
        entityAgreement: JSON.stringify(entityAgreement.agreementDetails), // we stringify this to make it smaller (reduces from ~200kb to ~80kb)
        primaryPalette: primaryPalette,
        intlMessages: await loadIntlMessages(context),
        inviteToken,
        endpoint: api.endpoint,
      },
    };
  } catch (error) {
    debug("getServerSideProps", error);
    debugger;
    // Lets make sure we clear the cookies if there are errors
    context.res.setHeader("set-cookie", [
      `${INVITES_TOKEN_COOKIE}=; max-age=0`,
    ]);

    const logError = () => {
      Sentry.captureException(error);
      logger.server.error(error);
    };

    if (error instanceof TokenError) {
      const tokenError = error.getClientError();
      time.end("tokenError");
      return {
        props: {
          tokenError,
        },
      };
    }

    if ("code" in error && (error.code === 404 || error.code === 500)) {
      time.end("errorCode");
      return {
        props: {
          errorCode: error.code,
          errorMessage: error.message,
        },
      };
    }

    // We wan't to detect these errors because they are not handled by the API
    logError();
    time.end();
    return {
      props: {
        errorCode: 500,
        errorMessage: error.message, // TODO - remove this in production
      },
    };
  }
};
